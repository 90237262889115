import type { FunctionComponent } from 'react'
import { Routes, Route } from 'react-router-dom'

import { RunNewTest } from './modules/RunNewTest'

import AppLayout from './components/AppLayout'
import { ViewTestQueue } from './modules/ViewTestQueue'
import { ViewCompletedTests } from './modules/ViewFinishedTests'
import { HttpErrorBoundary } from './components/HttpErrorBoundary'

const App: FunctionComponent = () => {
  return (
    <AppLayout>
      <Routes>
        <Route
          path="/"
          element={<HttpErrorBoundary element={<RunNewTest />} />}
        />
        <Route
          path="/queue"
          element={<HttpErrorBoundary element={<ViewTestQueue />} />}
        />
        <Route
          path="/finished"
          element={<HttpErrorBoundary element={<ViewCompletedTests />} />}
        />
      </Routes>
    </AppLayout>
  )
}

export default App
