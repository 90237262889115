import type { FC } from 'react'
import type { AutoCompleteItemId } from '@matillion/component-library'
import { Icon, SearchBar } from '@matillion/component-library'
import classes from './TestQueueHeader.module.scss'
import { SquareButton } from '../../../components/SquareButton'
import ListAutoComplete from '../../../components/ListAutoComplete'

export interface TestQueueHeaderProps {
  groups: string[] | undefined
  selectedGroup: AutoCompleteItemId | null
  onGroupPick: (group: AutoCompleteItemId | null) => void
  versions: string[] | undefined
  selectedVersion: AutoCompleteItemId | null
  onVersionPick: (version: AutoCompleteItemId | null) => void
  users: string[] | undefined
  selectedUser: AutoCompleteItemId | null
  onUserPick: (user: AutoCompleteItemId | null) => void
  search: string
  setSearch: (search: string) => void
  refreshFn: () => void
}

export const TestQueueHeader: FC<TestQueueHeaderProps> = ({
  groups,
  selectedGroup,
  onGroupPick,
  versions,
  selectedVersion,
  onVersionPick,
  users,
  selectedUser,
  onUserPick,
  search,
  setSearch,
  refreshFn
}) => {
  return (
    <>
      <>
        <ListAutoComplete
          placeholder="Group"
          availableItems={groups}
          value={selectedGroup}
          onChange={onGroupPick}
        />
        <ListAutoComplete
          placeholder="Group version"
          availableItems={versions}
          disabled={!selectedGroup}
          value={selectedVersion}
          onChange={onVersionPick}
        />
        <ListAutoComplete
          placeholder="User"
          availableItems={users}
          value={selectedUser}
          onChange={onUserPick}
        />
        <div className={classes.Search}>
          <SearchBar
            placeholder="Search tests"
            crossOrigin=""
            value={search}
            onChange={(input) => {
              setSearch(input.target.value)
            }}
          />
        </div>
        <SquareButton aria-label="refresh" onClick={refreshFn}>
          <Icon.Refresh />
        </SquareButton>
      </>
    </>
  )
}
