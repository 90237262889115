import type { FC } from 'react'
import { Button, Icon, Toaster } from '@matillion/component-library'
import classes from './FinishedTestRow.module.scss'
import { runNewTest } from '../../../api/telescope'

export interface RunTestProps {
  groupName: string
  groupVersion: string
  testName: string
}

export const RunTest: FC<RunTestProps> = ({
  groupName,
  groupVersion,
  testName
}) => {
  const toaster = Toaster.useToaster()

  const rerunTest = async () => {
    try {
      await runNewTest(groupName, groupVersion, testName)
      toaster.makeToast({
        title: 'Started rerunning test',
        type: 'success',
        message: `Successfully started rerunning ${testName}`
      })
    } catch (error) {
      toaster.makeToast({
        title: "Couldn't rerun test",
        type: 'error',
        message: (error as Error).message
      })
    }
  }

  return (
    <Button
      size="lg"
      onClick={() => {
        rerunTest()
      }}
    >
      <Icon.Play className={classes.TestFileRow__Icon} />
      <span>Run test again</span>
    </Button>
  )
}
