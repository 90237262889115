import type { FC } from 'react'
import { useMemo, useState } from 'react'
import type {
  AutoCompleteItemId,
  DataGridColumnProps
} from '@matillion/component-library'
import {
  LoadingSpinner,
  Button,
  DataGrid,
  Page
} from '@matillion/component-library'
import { PageContent } from '../../components/PageContent'
import { FilterableContainer } from '../../components/FilterableContainer'
import { FinishedTestsHeader } from './FinishedTestsHeader'
import { useInfiniteQuery } from '@tanstack/react-query'
import { getFinishedTests } from '../../api/telescope'
import { useGroups, useUsers, useVersions } from '../../hooks'
import type Job from '../../types/HTTP/Job'
import {
  CreationTime,
  FinishTime,
  GroupName,
  GroupVersion,
  RunTest,
  StartTime,
  TestName,
  TestUser
} from './FinishedTestRow'
import classes from './ViewCompletedTests.module.scss'

export const ViewCompletedTests: FC = () => {
  const [selectedGroup, setSelectedGroup] = useState<AutoCompleteItemId | null>(
    null
  )
  const [selectedVersion, setSelectedVersion] =
    useState<AutoCompleteItemId | null>(null)
  const [selectedUser, setSelectedUser] = useState<AutoCompleteItemId | null>(
    null
  )
  const [searchValue, setSearchValue] = useState<string>('')

  const tests = useInfiniteQuery({
    queryKey: [
      'finishedTests',
      selectedGroup?.name,
      selectedVersion?.name,
      selectedUser?.name,
      searchValue
    ],
    queryFn: async ({ pageParam }) =>
      getFinishedTests(
        pageParam,
        selectedGroup?.name,
        selectedVersion?.name,
        selectedUser?.name,
        searchValue
      ),
    initialPageParam: 0,
    getNextPageParam: (lastPage, _pages, page) =>
      lastPage.length === 0 ? undefined : page + 1
  })

  const testsData = useMemo(
    () => (tests.data ? tests.data.pages.flatMap((page) => page) : []),
    [tests.data]
  )

  const groups = useGroups()
  const versions = useVersions({ groupName: selectedGroup?.name })
  const users = useUsers()

  const onGroupPick = (group: AutoCompleteItemId | null) => {
    setSelectedVersion(null)
    setSelectedGroup(group)
  }

  const columns: Array<DataGridColumnProps<Job>> = [
    {
      key: 'testName',
      title: 'Test name',
      as: TestName,
      mapValues: (job) => ({
        testName: job.testName
      }),
      className: classes.ViewCompletedTests__IconLabel
    },
    {
      key: 'testVersion',
      title: 'Group version',
      as: GroupVersion,
      mapValues: (job) => ({
        groupVersion: job.groupVersion
      })
    },
    {
      key: 'groupName',
      title: 'Group',
      as: GroupName,
      mapValues: (job) => ({
        groupName: job.groupName
      }),
      className: classes.ViewCompletedTests__IconLabel
    },
    {
      key: 'testUser',
      title: 'Test user',
      as: TestUser,
      mapValues: (job) => ({
        username: job.username
      }),
      className: classes.ViewCompletedTests__IconLabel
    },
    {
      key: 'creationTime',
      title: 'Creation time',
      as: CreationTime,
      mapValues: (job) => ({
        creationTime: job.creationTime
      }),
      className: classes.ViewCompletedTests__IconLabel
    },
    {
      key: 'startTime',
      title: 'Start time',
      as: StartTime,
      mapValues: (job) => ({
        startTime: job.startTime
      }),
      className: classes.ViewCompletedTests__IconLabel
    },
    {
      key: 'finishTime',
      title: 'finishTime',
      as: FinishTime,
      mapValues: (job) => ({
        finishTime: job.finishTime
      }),
      className: classes.ViewCompletedTests__IconLabel
    },
    {
      key: 'rerunJob',
      title: '',
      as: RunTest,
      mapValues: (job) => ({
        groupName: job.groupName,
        groupVersion: job.groupVersion,
        testName: job.testName
      })
    }
  ]

  return (
    <Page>
      <PageContent>
        <FilterableContainer
          header={
            <FinishedTestsHeader
              groups={groups.data}
              selectedGroup={selectedGroup}
              onGroupPick={onGroupPick}
              versions={versions.data}
              selectedVersion={selectedVersion}
              onVersionPick={setSelectedVersion}
              users={users.data}
              selectedUser={selectedUser}
              onUserPick={setSelectedUser}
              search={searchValue}
              setSearch={setSearchValue}
            />
          }
        >
          <DataGrid
            columns={columns}
            rows={testsData.map((test) => ({
              ...test
            }))}
          />
        </FilterableContainer>
        {(tests.isLoading || tests.isFetchingNextPage) && (
          <LoadingSpinner
            className={classes.ViewCompletedTests__LoadingSpinner}
          />
        )}
        {tests.hasNextPage && !tests.isFetchingNextPage && (
          <Button
            size="lg"
            className={classes.ViewCompletedTests__LoadMoreButton}
            onClick={() => {
              tests.fetchNextPage()
            }}
          >
            Load more
          </Button>
        )}
      </PageContent>
    </Page>
  )
}
