import type { FC } from 'react'
import { useMemo, useState } from 'react'
import type {
  AutoCompleteItemId,
  DataGridColumnProps
} from '@matillion/component-library'
import {
  LoadingSpinner,
  Button,
  DataGrid,
  Page
} from '@matillion/component-library'
import { FilterableContainer } from '../../components/FilterableContainer'
import { useInfiniteQuery } from '@tanstack/react-query'
import { getTestQueue } from '../../api/telescope'
import { TestQueueHeader } from './TestQueueHeader'
import { PageContent } from '../../components/PageContent'
import { useGroups, useUsers, useVersions } from '../../hooks'
import type Job from '../../types/HTTP/Job'
import {
  CreationTime,
  GroupName,
  GroupVersion,
  StartTime,
  TestName
} from './TestQueueRow'
import classes from './ViewTestQueue.module.scss'
import { JobStatus } from './TestQueueRow/JobStatus'
import { TestUser } from '../ViewFinishedTests/FinishedTestRow'

export const ViewTestQueue: FC = () => {
  const [selectedGroup, setSelectedGroup] = useState<AutoCompleteItemId | null>(
    null
  )
  const [selectedVersion, setSelectedVersion] =
    useState<AutoCompleteItemId | null>(null)
  const [selectedUser, setSelectedUser] = useState<AutoCompleteItemId | null>(
    null
  )
  const [searchValue, setSearchValue] = useState<string>('')

  const tests = useInfiniteQuery({
    queryKey: [
      'queuedTests',
      selectedGroup?.name,
      selectedVersion?.name,
      selectedUser?.name,
      searchValue
    ],
    queryFn: async ({ pageParam }) =>
      getTestQueue(
        pageParam,
        selectedGroup?.name,
        selectedVersion?.name,
        selectedUser?.name,
        searchValue
      ),
    initialPageParam: 0,
    getNextPageParam: (lastPage, _pages, page) =>
      lastPage.length === 0 ? undefined : page + 1,
    refetchInterval: 5000
  })

  const testsData = useMemo(
    () => (tests.data ? tests.data.pages.flatMap((page) => page) : []),
    [tests.data]
  )

  const groups = useGroups()
  const versions = useVersions({ groupName: selectedGroup?.name })
  const users = useUsers()

  const onGroupPick = (group: AutoCompleteItemId | null) => {
    setSelectedVersion(null)
    setSelectedGroup(group)
  }

  const columns: Array<DataGridColumnProps<Job>> = [
    {
      key: 'testName',
      title: 'Test name',
      as: TestName,
      mapValues: (job) => ({
        testName: job.testName
      }),
      className: classes.ViewTestQueue__IconLabel
    },
    {
      key: 'testVersion',
      title: 'Group version',
      as: GroupVersion,
      mapValues: (job) => ({
        groupVersion: job.groupVersion
      })
    },
    {
      key: 'groupName',
      title: 'Group',
      as: GroupName,
      mapValues: (job) => ({
        groupName: job.groupName
      }),
      className: classes.ViewTestQueue__IconLabel
    },
    {
      key: 'testUser',
      title: 'Test user',
      as: TestUser,
      mapValues: (job) => ({
        username: job.username
      }),
      className: classes.ViewTestQueue__IconLabel
    },
    {
      key: 'creationTime',
      title: 'Creation time',
      as: CreationTime,
      mapValues: (job) => ({
        creationTime: job.creationTime
      }),
      className: classes.ViewTestQueue__IconLabel
    },
    {
      key: 'startTime',
      title: 'Start time',
      as: StartTime,
      mapValues: (job) => ({
        startTime: job.startTime,
        status: job.status
      }),
      className: classes.ViewTestQueue__IconLabel
    },
    {
      key: 'status',
      title: 'Status',
      as: JobStatus,
      mapValues: (job) => ({
        status: job.status
      })
    }
  ]

  return (
    <Page>
      <PageContent>
        <FilterableContainer
          header={
            <TestQueueHeader
              groups={groups.data}
              selectedGroup={selectedGroup}
              onGroupPick={onGroupPick}
              versions={versions.data}
              selectedVersion={selectedVersion}
              onVersionPick={setSelectedVersion}
              users={users.data}
              selectedUser={selectedUser}
              onUserPick={setSelectedUser}
              search={searchValue}
              setSearch={setSearchValue}
              refreshFn={() => {
                tests.refetch()
              }}
            />
          }
        >
          <DataGrid
            columns={columns}
            rows={testsData.map((test) => ({
              ...test
            }))}
          />
        </FilterableContainer>
        {(tests.isLoading || tests.isFetchingNextPage) && (
          <LoadingSpinner className={classes.ViewTestQueue__LoadingSpinner} />
        )}
        {tests.hasNextPage && !tests.isFetchingNextPage && (
          <Button
            size="lg"
            className={classes.ViewTestQueue__LoadMoreButton}
            onClick={() => {
              tests.fetchNextPage()
            }}
          >
            Load more
          </Button>
        )}
      </PageContent>
    </Page>
  )
}
