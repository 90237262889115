import type { FC } from 'react'
import { Icon } from '@matillion/component-library'
import classes from './FinishedTestRow.module.scss'

export interface TestUserProps {
  username: string
}

export const TestUser: FC<TestUserProps> = ({ username }) => {
  return (
    <>
      <Icon.Person className={classes.FinishedTestRow__Icon} />
      <span>{username}</span>
    </>
  )
}
