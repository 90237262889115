import JobStatus from '../../../types/HTTP/JobStatus'
import type { FC } from 'react'
import { Icon } from '@matillion/component-library'
import classes from './TestQueueRow.module.scss'

export interface StartTimeProps {
  startTime: Date
  status: JobStatus
}

export const StartTime: FC<StartTimeProps> = ({ startTime, status }) => {
  return status === JobStatus.Pending ? null : (
    <>
      <Icon.Play className={classes.TestQueueRow__Icon} />
      <span>{startTime.toLocaleString()}</span>
    </>
  )
}
