import type { FC, ReactNode } from 'react'
import classNames from 'classnames'
import React from 'react'
import classes from './FilterableContainer.module.scss'

export interface FilterableContainerProps {
  header: ReactNode
  className?: string
}

export const FilterableContainer: FC<
  React.PropsWithChildren<FilterableContainerProps>
> = ({ className, header, children }) => {
  return (
    <section className={classNames(classes.Container, className)}>
      <div className={classes.Container__Header}>{header}</div>
      <div>{children}</div>
    </section>
  )
}
