import type { FC, PropsWithChildren } from 'react'
import {
  Layout,
  AppMenu,
  AppMenuItem,
  Icon,
  Header
} from '@matillion/component-library'
import { Link, useLocation } from 'react-router-dom'

const AppLayout: FC<PropsWithChildren> = ({ children }) => {
  const url = useLocation().pathname
  const newTestPath = '/'
  const runningTestsPath = '/queue'
  const finishedTestsPath = '/finished'

  return (
    <Layout
      header={<Header />}
      appMenu={
        <AppMenu>
          <AppMenuItem
            active={url === newTestPath}
            name="New"
            icon={<Icon.File />}
            as={Link}
            to={newTestPath}
          />
          <AppMenuItem
            active={url === runningTestsPath}
            name="Running"
            icon={<Icon.InfoNoBg />}
            as={Link}
            to={runningTestsPath}
          />
          <AppMenuItem
            active={url === finishedTestsPath}
            name="Finished"
            icon={<Icon.Tick />}
            as={Link}
            to={finishedTestsPath}
          />
        </AppMenu>
      }
    >
      {children}
    </Layout>
  )
}

export default AppLayout
