import type { FC } from 'react'
import type { AutoCompleteItemId } from '@matillion/component-library'
import { SearchBar } from '@matillion/component-library'
import classes from './FinishedTestsHeader.module.scss'
import ListAutoComplete from '../../../components/ListAutoComplete'

export interface CompletedTestsHeaderProps {
  groups: string[] | undefined
  selectedGroup: AutoCompleteItemId | null
  onGroupPick: (group: AutoCompleteItemId | null) => void
  versions: string[] | undefined
  selectedVersion: AutoCompleteItemId | null
  onVersionPick: (version: AutoCompleteItemId | null) => void
  users: string[] | undefined
  selectedUser: AutoCompleteItemId | null
  onUserPick: (user: AutoCompleteItemId | null) => void
  search: string
  setSearch: (search: string) => void
}

export const FinishedTestsHeader: FC<CompletedTestsHeaderProps> = ({
  groups,
  selectedGroup,
  onGroupPick,
  versions,
  selectedVersion,
  onVersionPick,
  users,
  selectedUser,
  onUserPick,
  search,
  setSearch
}) => {
  return (
    <>
      <ListAutoComplete
        placeholder="Group"
        availableItems={groups}
        value={selectedGroup}
        onChange={onGroupPick}
      />
      <ListAutoComplete
        placeholder="Group version"
        availableItems={versions}
        disabled={!selectedGroup}
        value={selectedVersion}
        onChange={onVersionPick}
      />
      <ListAutoComplete
        placeholder="User"
        availableItems={users}
        value={selectedUser}
        onChange={onUserPick}
      />
      <div className={classes.Search}>
        <SearchBar
          placeholder="Search tests"
          crossOrigin=""
          value={search}
          onChange={(input) => {
            setSearch(input.target.value)
          }}
        />
      </div>
    </>
  )
}
