import type { FC } from 'react'
import { Icon } from '@matillion/component-library'
import classes from './TestQueueRow.module.scss'

export interface GroupNameProps {
  groupName: string
}

export const GroupName: FC<GroupNameProps> = ({ groupName }) => {
  return (
    <>
      <Icon.People className={classes.FinishedTestRow__Icon} />
      <span>{groupName}</span>
    </>
  )
}
