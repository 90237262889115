import type { FC } from 'react'
import type {
  AutoCompleteItemId,
  DataGridColumnProps
} from '@matillion/component-library'
import {
  LoadingSpinner,
  Toaster,
  DataGrid,
  Page
} from '@matillion/component-library'
import { TestPickerHeader } from './TestPickerHeader'
import { FilterableContainer } from '../../components/FilterableContainer'
import { useState } from 'react'
import { getTests, runNewTest } from '../../api/telescope'
import { useQuery } from '@tanstack/react-query'
import { PageContent } from '../../components/PageContent'
import { useGroups, useVersions } from '../../hooks'
import { RunTest, TestName } from './TestFileRow'
import classes from './RunNewTest.module.scss'

export const RunNewTest: FC = () => {
  const toaster = Toaster.useToaster()

  const [selectedGroup, setSelectedGroup] = useState<AutoCompleteItemId | null>(
    null
  )
  const [selectedVersion, setSelectedVersion] =
    useState<AutoCompleteItemId | null>(null)
  const [searchValue, setSearchValue] = useState<string>('')

  const groups = useGroups()
  const versions = useVersions({ groupName: selectedGroup?.name })

  const tests = useQuery({
    queryKey: ['testToRun', selectedGroup?.name, selectedVersion?.name],
    queryFn: async () =>
      getTests(selectedGroup?.name ?? '', selectedVersion?.name ?? ''),
    enabled: !!selectedGroup && !!selectedVersion
  })

  const displayTests = (tests.data ?? []).filter((test) =>
    test.toLowerCase().includes(searchValue.toLowerCase())
  )

  const onGroupPick = (item: AutoCompleteItemId | null) => {
    setSelectedGroup(item)
    setSelectedVersion(null)
  }

  const onSearchChange = (event: { target: { value: string } }) => {
    setSearchValue(event.target.value)
  }

  const onRunTestClick = async (test: string) => {
    if (selectedGroup?.name && selectedVersion?.name) {
      try {
        await runNewTest(selectedGroup.name, selectedVersion.name, test)
        toaster.makeToast({
          title: 'Test started',
          type: 'success',
          message: 'Test successfully started'
        })
      } catch (error) {
        toaster.makeToast({
          title: 'Error occurred trying to run test',
          type: 'error',
          message: (error as Error).message
        })
      }
    }
  }

  const columns: Array<DataGridColumnProps<{ testName: string }>> = [
    {
      key: 'testName',
      title: 'Test name',
      as: TestName,
      mapValues: (test) => ({
        testName: test.testName
      }),
      className: classes.RunNewTest__TestName
    },
    {
      key: 'runTest',
      title: '',
      as: RunTest,
      mapValues: (test) => ({
        testName: test.testName,
        onClickRun: () => {
          onRunTestClick(test.testName)
        }
      }),
      className: classes.RunNewTest__RightColumn
    }
  ]

  return (
    <Page>
      <PageContent>
        <FilterableContainer
          header={
            <TestPickerHeader
              selectedGroup={selectedGroup}
              groups={groups.data}
              onGroupPick={onGroupPick}
              selectedVersion={selectedVersion}
              versions={versions.data}
              onVersionPick={setSelectedVersion}
              searchValue={searchValue}
              onSearchChange={onSearchChange}
            />
          }
        >
          <DataGrid
            columns={columns}
            rows={displayTests.map((data) => ({
              id: data,
              testName: data
            }))}
          />
        </FilterableContainer>
        {tests.isLoading && (
          <LoadingSpinner className={classes.RunNewTest__LoadingSpinner} />
        )}
      </PageContent>
    </Page>
  )
}
