import { useQuery } from '@tanstack/react-query'
import { getVersions } from '../api/telescope'
import type { UseQueryHookOptions } from './types'

export interface UseVersionOptions extends UseQueryHookOptions {
  groupName: string | undefined
}

export const useVersions = ({
  enabled = true,
  refetchInterval = false,
  groupName
}: UseVersionOptions) =>
  useQuery({
    queryKey: ['versions', groupName],
    queryFn: async () => getVersions(groupName ?? ''),
    enabled: !!groupName && enabled,
    refetchInterval: refetchInterval
  })
