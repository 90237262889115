import type { UseQueryHookOptions } from './types'
import { useQuery } from '@tanstack/react-query'
import { getUsers } from '../api/telescope'

export const useUsers = ({
  enabled = true,
  refetchInterval = false
}: UseQueryHookOptions = {}) =>
  useQuery({
    queryKey: ['users'],
    queryFn: getUsers,
    enabled: enabled,
    refetchInterval: refetchInterval
  })
