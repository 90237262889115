import type { FC } from 'react'
import { Icon } from '@matillion/component-library'
import classes from './FinishedTestRow.module.scss'

export interface CreationTimeProps {
  creationTime: Date
}

export const CreationTime: FC<CreationTimeProps> = ({ creationTime }) => {
  return (
    <>
      <Icon.Cog className={classes.FinishedTestRow__Icon} />
      <span>{creationTime.toLocaleString()}</span>
    </>
  )
}
