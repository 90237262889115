const version = process.env.REACT_APP_VERSION ?? '0.0.0-local'
const environment = process.env.REACT_APP_ENVIRONMENT ?? 'local'

type EnvironmentConfig = {
  DATADOG?: Record<string, string>
} & {
  [key: string]: string | undefined
}

// eslint-disable-next-line @typescript-eslint/no-var-requires
const envConfig: EnvironmentConfig = require(`./${environment}.json`)

const config = {
  environment,
  version,

  authEnvironment: envConfig.AUTH_ENVIRONMENT,

  gtmId: envConfig.GTM_ID,

  datadog: envConfig.DATADOG && {
    env: envConfig.DATADOG.ENV,
    site: envConfig.DATADOG.SITE,
    clientToken: envConfig.DATADOG.CLIENT_TOKEN,
    service: envConfig.DATADOG.SERVICE,
    rumService: envConfig.DATADOG.RUM_SERVICE,
    applicationId: envConfig.DATADOG.APPLICATION_ID,
    apiOrigin: envConfig.DATADOG.API_ORIGIN
  }
}

export default config
