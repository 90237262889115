import type { FC } from 'react'
import { Icon } from '@matillion/component-library'
import classes from './TestFileRow.module.scss'

export interface TestNameProps {
  testName: string
}

export const TestName: FC<TestNameProps> = ({ testName }) => {
  return (
    <>
      <Icon.File className={classes.TestFileRow__Icon} />
      <span>{testName}</span>
    </>
  )
}
