/* eslint-disable @typescript-eslint/no-unused-vars */
import './base.scss'

import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import TagManager from 'react-gtm-module'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

import config from './config'
import App from './App'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import Auth from './components/Auth'
import { ErrorBoundary } from './components/ErrorBoundary'
import { Toaster } from '@matillion/component-library'

if (config.datadog) {
  const { rumService, service, apiOrigin, ...options } = config.datadog

  datadogLogs.init({
    ...options,
    service,
    forwardErrorsToLogs: true,
    sampleRate: 100
  })

  datadogRum.init({
    ...options,
    version: config.version,
    service: rumService,
    sampleRate: 100,
    allowedTracingOrigins: [apiOrigin]
  })
}

if (config.gtmId) {
  TagManager.initialize({ gtmId: config.gtmId })
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const rootEl = document.getElementById('root')!
const root = createRoot(rootEl)

rootEl.setAttribute('data-app-environment', config.environment)
rootEl.setAttribute('data-app-version', config.version)

const queryClient = new QueryClient({
  defaultOptions: { queries: { throwOnError: true } }
})

root.render(
  <StrictMode>
    <Toaster.Provider theme="light" align="right" withFade>
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <Auth>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </Auth>
        </QueryClientProvider>
      </ErrorBoundary>
    </Toaster.Provider>
  </StrictMode>
)
