import type { ReactNode } from 'react'
import React from 'react'
import { ErrorBoundaryBase } from '../ErrorBoundaryBase'
import type { AxiosError } from 'axios'
import classes from './HttpErrorBoundary.module.scss'
import { Button, Typography } from '@matillion/component-library'

export interface HttpErrorBoundaryProps {
  title?: string
  message?: string
  element: ReactNode
}

export class HttpErrorBoundary extends ErrorBoundaryBase<HttpErrorBoundaryProps> {
  componentDidUpdate(prevProps: HttpErrorBoundaryProps) {
    if (prevProps.element !== this.props.element) {
      this.setState({ error: null, hasError: false })
    }
  }

  componentDidCatch(error: Error) {
    super.componentDidCatch(error)
    if (!(error as AxiosError)?.isAxiosError) throw error
  }

  render() {
    if (this.state.hasError) {
      if ((this.state.error as AxiosError)?.isAxiosError) {
        return (
          <div className={classes.HttpErrorBoundary}>
            <Typography format="dts">
              {this.props.title ?? 'Something went wrong'}
            </Typography>
            <Typography format="ts">
              {this.props.message ?? 'Try reloading the page'}
            </Typography>
            <Button
              className={classes.HttpErrorBoundary__Reload}
              alt="primary"
              text="Reload"
              fontWeight="bold"
              onClick={() => {
                this.setState({ error: null, hasError: false })
              }}
            />
          </div>
        )
      } else {
        return null
      }
    }

    return this.props.element
  }
}
