import { Button, Icon } from '@matillion/component-library'
import classes from './TestFileRow.module.scss'
import type { FC } from 'react'

export interface RunTestProps {
  testName: string
  onClickRun: (testName: string) => void
}

export const RunTest: FC<RunTestProps> = ({ testName, onClickRun }) => {
  return (
    <Button
      size="lg"
      onClick={() => {
        onClickRun(testName)
      }}
    >
      <Icon.Play className={classes.TestFileRow__Icon} />
      <span>Run test</span>
    </Button>
  )
}
