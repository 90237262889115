import type { ReactNode } from 'react'
import React from 'react'
import { Button, Typography } from '@matillion/component-library'
import classes from './ErrorBoundary.module.scss'
import { ErrorBoundaryBase } from '../ErrorBoundaryBase'

export class ErrorBoundary extends ErrorBoundaryBase<{ children: ReactNode }> {
  render() {
    if (this.state.hasError) {
      return (
        <div className={classes.ErrorBoundary}>
          <Typography format="dtm">Something went wrong</Typography>
          <Typography>{this.state.error?.message}</Typography>
          <a href="/" className={classes.ErrorBoundary__Reload}>
            <Button as="span" alt="primary" text="Reload" fontWeight="bold" />
          </a>
        </div>
      )
    }

    return this.props.children
  }
}
