import type { FC, PropsWithChildren } from 'react'
import { useEffect, useState } from 'react'
import { testAuth } from '../api/telescope'

const Auth: FC<PropsWithChildren> = ({ children }) => {
  const [auth, setAuth] = useState(false)

  useEffect(() => {
    const fn = async () => {
      setAuth(await testAuth())
    }
    fn()
  }, [])

  return auth ? <>{children}</> : null
}

export default Auth
