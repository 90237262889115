import type { FC } from 'react'
import { Icon } from '@matillion/component-library'
import classes from './FinishedTestRow.module.scss'

export interface FinishTimeProps {
  finishTime: Date
}

export const FinishTime: FC<FinishTimeProps> = ({ finishTime }) => {
  return (
    <>
      <Icon.Tick className={classes.FinishedTestRow__Icon} />
      <span>{finishTime.toLocaleString()}</span>
    </>
  )
}
