import type { AxiosRequestConfig, AxiosError } from 'axios'
import axios from 'axios'
import type Job from '../types/HTTP/Job'

const iso8601regex =
  /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/

const telescopeApi = axios.create({
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  },
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
  transformResponse: (data) => {
    if (data.length === 0) return data
    return JSON.parse(data, (key, value) =>
      (key.toLowerCase().includes('date') ||
        key.toLowerCase().includes('time')) &&
      iso8601regex.test(value)
        ? new Date(value)
        : value
    )
  }
})

telescopeApi.interceptors.response.use(undefined, (error) => {
  const axiosError = error as AxiosError
  if (axiosError.response && axiosError.response.status >= 400) {
    if (axiosError.response.status === 401) {
      window.location.href =
        (process.env.REACT_APP_API_BASE_URL ?? '') + '/api/login'
    }
    throw axiosError
  }
})

export const testAuth = async (requestConfig?: AxiosRequestConfig) => {
  try {
    await telescopeApi.get('/v1/groups')
    return true
  } catch (error: unknown) {
    if (
      (error as AxiosError).isAxiosError &&
      (error as AxiosError).response?.status === 401
    ) {
      return false
    }
  }
  return false
}

export const getGroups = async (requestConfig?: AxiosRequestConfig) => {
  const response = await telescopeApi.get<string[]>('/v1/groups')
  return response.data
}
export const getVersions = async (
  group: string,
  requestConfig?: AxiosRequestConfig
) => {
  const response = await telescopeApi.get<string[]>(
    `/v1/groups/${group}/versions`
  )
  return response.data
}

export const getTests = async (
  group: string,
  version: string,
  requestConfig?: AxiosRequestConfig
) => {
  const response = await telescopeApi.get<string[]>(
    `/v1/groups/${group}/${version}/files`
  )
  return response.data
}

export const runNewTest = async (
  group: string,
  version: string,
  test: string,
  requestConfig?: AxiosRequestConfig
) => {
  const response = await telescopeApi.post('/v1/jobs/createJob', {
    groupName: group,
    groupVersion: version,
    testPath: test
  })
  return response.data
}

export const getTestQueue = async (
  page: number,
  groupName?: string,
  groupVersion?: string,
  username?: string,
  search?: string
) => {
  const params = {
    page,
    ...(!!groupName && { groupName }),
    ...(!!groupVersion && { groupVersion }),
    ...(!!username && { username }),
    ...(!!search && { search })
  }
  const response = await telescopeApi.get<Job[]>('/v1/jobs/running', {
    params
  })
  return response.data
}

export const getFinishedTests = async (
  page: number,
  groupName?: string,
  groupVersion?: string,
  username?: string,
  search?: string
) => {
  const params = {
    page,
    ...(!!groupName && { groupName }),
    ...(!!groupVersion && { groupVersion }),
    ...(!!username && { username }),
    ...(!!search && { search })
  }
  const response = await telescopeApi.get<Job[]>('/v1/jobs/finished', {
    params
  })
  return response.data
}

export const getUsers = async () => {
  const response = await telescopeApi.get<string[]>('/v1/users')
  return response.data
}

export default telescopeApi
