import type { FC } from 'react'
import type {
  AutoCompleteItemId,
  AutoCompleteProps
} from '@matillion/component-library'
import { AutoComplete } from '@matillion/component-library'

export interface ListAutoCompleteProps
  extends Omit<AutoCompleteProps, 'onChange'> {
  availableItems: string[] | undefined
  onChange: (value: AutoCompleteItemId | null) => void
}

const ListAutoComplete: FC<ListAutoCompleteProps> = ({
  availableItems,
  onChange,
  ...otherProps
}) => {
  const formattedItems =
    availableItems === undefined
      ? [{ id: 1, name: 'Loading', disabled: true }]
      : availableItems.length === 0
      ? [{ id: 1, name: 'No results', disabled: true }]
      : availableItems.map((item, index) => ({ id: index + 1, name: item }))

  return (
    <AutoComplete
      {...otherProps}
      onChange={(choice) => {
        onChange(choice.target.value)
      }}
      availableItems={formattedItems}
    />
  )
}

export default ListAutoComplete
