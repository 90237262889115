import type { FC } from 'react'
import type { AutoCompleteItemId } from '@matillion/component-library'
import { SearchBar } from '@matillion/component-library'
import classes from './TestPickerHeader.module.scss'
import ListAutoComplete from '../../../components/ListAutoComplete'

export interface TestPickerHeaderProps {
  selectedGroup: AutoCompleteItemId | null
  groups: string[] | undefined
  onGroupPick: (event: AutoCompleteItemId | null) => void
  selectedVersion: AutoCompleteItemId | null
  versions: string[] | undefined
  onVersionPick: (event: AutoCompleteItemId | null) => void
  searchValue: string
  onSearchChange: (event: { target: { value: string } }) => void
}

export const TestPickerHeader: FC<TestPickerHeaderProps> = ({
  selectedGroup,
  groups,
  onGroupPick,
  selectedVersion,
  versions,
  onVersionPick,
  searchValue,
  onSearchChange
}) => {
  return (
    <>
      <ListAutoComplete
        placeholder="Group"
        availableItems={groups}
        value={selectedGroup}
        onChange={onGroupPick}
      />
      <ListAutoComplete
        placeholder="Group version"
        availableItems={versions}
        disabled={!selectedGroup}
        value={selectedVersion}
        onChange={onVersionPick}
      />
      <div className={classes.Search}>
        <SearchBar
          placeholder="Search tests"
          crossOrigin=""
          disabled={!selectedGroup || !selectedVersion}
          value={searchValue}
          onChange={onSearchChange}
        />
      </div>
    </>
  )
}
