import { useQuery } from '@tanstack/react-query'
import { getGroups } from '../api/telescope'
import type { UseQueryHookOptions } from './types'

export const useGroups = ({
  enabled = true,
  refetchInterval = false
}: UseQueryHookOptions = {}) =>
  useQuery({
    queryKey: ['groups'],
    queryFn: getGroups,
    enabled: enabled,
    refetchInterval: refetchInterval
  })
