import { Component } from 'react'

export interface ErrorBoundaryState {
  hasError: boolean
  error: Error | null
}

export class ErrorBoundaryBase<T> extends Component<T> {
  state: ErrorBoundaryState = { hasError: false, error: null }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error) {
    this.setState({ error: error })
  }
}
