import type {
  ButtonHTMLAttributes,
  FC,
  MouseEventHandler,
  PropsWithChildren
} from 'react'
import classes from './SquareButton.module.scss'

export interface SquareButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  'aria-label': string
  onClick?: MouseEventHandler
  disabled?: boolean
}

export const SquareButton: FC<PropsWithChildren<SquareButtonProps>> = ({
  'aria-label': ariaLabel,
  children,
  disabled = false,
  onClick,
  ...otherProps
}) => {
  return (
    <button
      type="button"
      {...otherProps}
      className={classes.SquareButton}
      aria-label={ariaLabel}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  )
}
